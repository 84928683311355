<template>
  <section class="list container">
    <div id="results" class="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>
          {{ title }}
          <span class="i-tooltip" v-b-tooltip.hover :title="tooltip" v-if="tooltip">
            <i-tooltip />
          </span>
        </h2>
        <ToggleArrow v-if="toggle === true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
        </ToggleArrow>
      </div>
      <div v-if="toggleMe && items.length === 0" style="margin-left: 50px">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            {{ noRecordsMessage }}
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="items.length > 0">
        <header v-if="toggleMe && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <a v-if="csvUrl.length > 0" class="btn btn-dark flex-0 mr-2"
              @click.prevent="downloadReport(csvUrl, reportName, 'csv')">{{ i18n['data-table-store'].tcExportCSV }}</a>
            <a v-if="pdfUrl.length > 0" class="btn btn-dark flex-0 mr-2"
              @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')">{{ i18n['data-table-store'].tcExportPDF }}</a>
            <b-form-input v-model="filter" @input="handleSearchTermChange"
              :placeholder="i18n['data-table-store'].tcFilter"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table class="g-table" :fields="fields" :small="smallTable" :items="items" :per-page="perPage"
            :current-page="currentPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :table-variant="tableVariant" sort-icon-left>
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px">?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(vprd_product_name)="data">
              <a href="#" @click.prevent="navProductUrl(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(odd_odr_id)="data">
              <a href="#" @click.prevent="data.toggleDetails">{{ data.value }}</a>
            </template>
            <template v-slot:cell(prd_code_normalized)="data">
              {{ data.item.prd_code }}
            </template>
            <template v-slot:cell(prd_name)="data">
              <a href="#" @click.prevent="navProductUrl(data)">{{ data.value }}</a>
            </template>
            <template v-slot:cell(event_active_flag)="data">
              <b-form-checkbox v-model="data.value" name="check-button" switch @change="toggleActive(data)">
                {{ data.value ? i18n['data-table-store'].tcActive : i18n['data-table-store'].tcInactive }}
              </b-form-checkbox>
            </template>
            <template v-slot:cell(shc_quantity)="data">
              <b-form-input @change="quantityChanged(data)" v-model="data.item.shc_quantity" min="0" type="number"
                class="formQuantity"></b-form-input>
            </template>
            <template v-slot:cell(TemplateName)="data">
              {{
              data.value === 'TC3_Store_Product_Merchandise'
              ? i18n['data-table-store'].tcMerch
              : i18n['data-table-store'].tcScripture
              }}
            </template>
            <template v-slot:cell(prd_is_available_flag_cp)="data">
              {{ '$' + data.item.prd_odd_total }}
            </template>
            <template v-slot:cell(sfv_prd_key)="data">
              <div class="service-action-bar">
                <div @click="deleteItem(data)" class="mr-3">
                  <ServiceDelete />
                </div>
                <div @click="addFavoriteToCart(data)">
                  <ServiceAdd />
                </div>
              </div>
            </template>
            <template v-slot:cell(odd_key)="data">
              <div class="service-action-bar">
                <div @click="addOrder(data)">
                  <ServiceAdd />
                </div>
              </div>
            </template>
            <template v-slot:cell(prd_key)="data">
              <div class="service-action-bar">
                <div @click="addSearchToCart(data)">
                  <ServiceAdd />
                </div>
              </div>
            </template>
            <template v-slot:cell(shc_prd_key)="data">
              <div class="service-action-bar">
                <div @click="deleteItem(data)" class="mr-3">
                  <ServiceDelete />
                </div>
              </div>
            </template>
            <template #row-details="data" style="background-color: #cccccc">
              <data-table :fields="OrderHistoryProductsFields" :items="data.item.Products"
                :noRecordsMessage="noRecordsMessage" :hidePagination="trueValue" :hideHeader="trueValue"
                :smallTable="trueValue" :tableVariant="`secondary`"></data-table>
            </template>
          </b-table>
        </main>
        <footer v-if="toggleMe && !hidePagination" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-store'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" @click="nextPage" :class="{
            hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage,
          }">
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import ServiceAdd from '@/assets/svgs/icon-add-button-yellow.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table-store',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-store': {
            tcActive: 'Active',
            tcAmount: 'Amount',
            tcCancel: 'Cancel',
            tcDate: 'Date',
            tcExportCSV: 'Export CSV',
            tcExportPDF: 'Export PDF',
            tcFilter: 'Filter',
            tcInactive: 'Inactive',
            tcMerch: 'Merch',
            tcOk: 'Ok',
            tcOptions: 'Options',
            tcOrderID: 'Order ID',
            tcPrice: 'Price',
            tcProduct: 'ProductInitial',
            tcQuantity: 'Quantity',
            tcScripture: 'Scripture',
            tcShow: 'Show',
            tcTotal: 'Total',
          },
        }
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    noRecordsMessage: {
      type: String,
      default: 'No records were found.',
    },
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    tableVariant: {
      type: String,
      default: '',
    },
    addItems: {
      type: Object,
      default: () => {
        return {
          text: 'Add',
          display: false,
          action: '#',
        }
      },
    },
    hideHeader: { type: Boolean, default: false },
    hideFilter: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    smallTable: { type: Boolean, default: false },
    title: String,
    toggle: Boolean,
  },
  data() {
    return {
      // Placement Table Settings
      sortBy: 'Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true,
      trueValue: true,
      OrderHistoryProductsFields: [
        { key: 'prd_item_and_product_name', label: this.i18n['data-table-store'].tcProduct, sortable: true },
        { key: 'prd_quantity', label: this.i18n['data-table-store'].tcQuantity, sortable: true },
        {
          key: 'prd_price',
          label: this.i18n['data-table-store'].tcPrice,
          sortable: true,
          formatter: (value, key, item) => '$' + Number(value).toFixed(2),
        },
        { key: 'prd_is_available_flag_cp', label: this.i18n['data-table-store'].tcTotal, sortable: false },
      ],
    }
  },
  methods: {
    ...mapActions({
      setSearchTerm: 'search/setSearchTerm',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
    }),
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    navProductUrl(data) {
      let prdkey = ''
      if (data.item.sfv_prd_key) {
        prdkey = data.item.sfv_prd_key
      } else if (data.item.shc_prd_key) {
        prdkey = data.item.shc_prd_key
      } else if (data.item.prd_key) {
        prdkey = data.item.prd_key
      }
      let urlPath = '/resources/store/product-details/' + prdkey
      this.$router.push({ path: urlPath })
    },
    triggerAction(action) {
      this.$emit(action)
    },
    emitAction(action, data) {
      this.$emit(action, data)
    },
    log(item) { },
    type(val) {
      return typeof val
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async quantityChanged(data) {
      this.$emit('itemQuantityChanged', data.item)
    },
    async addFavoriteToCart(data) {
      this.$emit('addFavorite', data.item.sfv_prd_key)
    },
    async addSearchToCart(data) {
      this.$emit('addSearchItem', data.item.prd_key)
    },
    async addOrder(data) {
      this.$emit('addOrder', data.item)
    },
    async deleteItem(data) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.i18n['data-table-store'].tcOk,
        cancelButtonText: this.i18n['data-table-store'].tcCancel,
        cancelButtonColor: '#d33',
        text: this.i18n['data-table-store'].tcOkayToDelete || `This will be deleted. Is that Ok?`,
      }).then((result) => {
        if (result.value) {
          this.$emit('deleteItem', data.item)
        }
      })
    },
    async handleSearchTermChange() {
      await this.setSearchTerm({ gideonStoreTerm: this.filter })
    },
    async locationStorePlacementKeyAndRedirect(data) {
      await this.setSelectedLocationKey(data.item.plf_org_key)
      await this.setSelectedPlacementDateKey(data.item.mtg_key)
      this.$router.push({ name: 'placement-details' })
    },
    individualStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data)
      this.$router.push('/programs/mb/member-profile/')
    },
    toggleActive(data) {
      setTimeout(() => {
        // this is to give the change event a tick to update the value
        this.emitAction('activeToggleEvent', data)
      }, 0)
    },
  },
  computed: {
    ...mapGetters({
      searchTerms: 'search/searchTerms',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    rows() {
      return this.items ? this.items.length : 0
    },
  },
  created() {
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.gideonStoreTerm
  },
  components: {
    dataTable: () => import('@/components/DataTableGideonStore.vue'),
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    iTooltip: iTooltip,
    ServiceAdd: ServiceAdd,
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.i-tooltip {
  vertical-align: super;

  svg path {
    fill: #000 !important;
  }
}

.service-action-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  >div {
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }

    svg {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.formQuantity {
  min-width: 50px;
  max-width: 70px;
  padding: 12px;
  text-align: center;
}

.results,
.container {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0;
}

svg {
  g {
    fill: #000000;
  }
}

.lower {
  text-transform: lowercase;
}
</style>
